import React from "react";
import { Carousel } from "react-bootstrap";
import img1 from "./images/about.jpg";
import img2 from "./images/about2.jpg";
import img3 from "./images/about3.jpg";
import img4 from "./images/about4.jpg";

const Carousel1 = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
        style={{
          height: "100%",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <ol class="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            class="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <Carousel class="carousel-inner">
          {[img1, img2, img3, img4].map(pic =>
          <Carousel.Item>
            <div class="carousel-item"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <img
                src={pic}
                alt="First slide"
                style={{
                  // width: "100vw",
                  height: "100vh",
                }}
              />
            </div>
            </Carousel.Item>
          )}


        </Carousel>
        <a
          class="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  );
};

export default Carousel1;
