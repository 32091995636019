import "./App.css";
import Carousel1 from "./components/Carousel/Carousel1";
import { useState, useEffect } from "react";

import Carousel2 from "./components/Carousel/Carousel2";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Info from "./components/Info/Info";
import Home from "./pages/home/Home";
import TabsFooter from "./components/Footer/TabsFooter";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const resolution = window.innerWidth;
    if (resolution >= 320 && resolution <= 480) {
      setIsMobile(true);
    }
  }, []);
  return (
    <div className="App">
      <Header />
      <div
        className="app_itemcontainer"
        style={{
          overflowX: "hidden",
        }}
      >
        <Home />

        {/* <Info />
        <Carousel1 />
        <div
          style={{
            backgroundColor: "#D0D0D0",
            height: "50px",
            width: "100vw",
          }}
        ></div>
        <div style={{
            backgroundColor: "#D0D0D0",
          }}><div style={{ fontSize: "40px", textAlign: "center", fontWeight: "bold" }}>Testimonials</div></div>
        <Carousel2 />
        <div
          style={{
            backgroundColor: "#D0D0D0",
            height: "90px",
            width: "100vw",
          }}
        ></div> */}
        <Footer />
        {isMobile && <TabsFooter />}
      </div>
    </div>
  );
}

export default App;
