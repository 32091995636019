import React from "react";
import facebook from "./images/facebook.png";
import instagram from "./images/instagram.png";
import twitter from "./images/twitter.png";
import youtube from "./images/youtube.png";
const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: "#282828",
        height: "20vh",
        //marginBottom: "10vh",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          paddingTop: 20,
        }}
      >
        <div>
          <h6
            style={{
              color: "white",
              margin: 20,
            }}
          >
            © Wellzap
          </h6>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: 20,
          }}
        >
          <img src={facebook} width="30px" />
          <img
            src={twitter}
            width="30px"
            style={{
              marginLeft: 10,
            }}
          />
          <img
            src={instagram}
            width="30px"
            style={{
              marginLeft: 10,
            }}
          />
          <img
            src={youtube}
            width="40px"
            style={{
              marginLeft: 10,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
