import React from "react";
import paper from "./images/newspaper.png";
import place from "./images/placeholder.png";
import phone from "./images/phone.png";
import mail from "./images/mail.png";
const TabsFooter = () => {
  return (
    <div
      style={{
        backgroundColor: "#282828",
        height: "10vh",
        width: "100vw",
        position: "fixed",
        bottom: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          margin: 20,
        }}
      >
        <img src={paper} width="30px" />
        <img
          onClick={() => window.open('google.navigation:q=San+Francisco')}
          src={place}
          width="30px"
          style={{
            marginLeft: 10,
          }}
        />
        <img
          onClick={() => window.open("tel:+918660506460")}
          src={phone}
          width="30px"
          style={{
            marginLeft: 10,
          }}
        />
        <img
        onClick={() => window.open('mailto:mscacademyindia@gmail.com')}
          src={mail}
          width="40px"
          style={{
            marginLeft: 10,
          }}
        />
      </div>
    </div>
  );
};

export default TabsFooter;