import React, { useState } from "react";
import "./Header.css";
import img1 from "./images/logo.jpeg";
import img2 from "./images/menu.png";
import CloseImg from './images/close.png'
const Header = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [modal, setModal] = useState(false);
  return (
    <div className="header">
      <div className="header_content">
        <div className="header_logo">
          <img src={img1} />
        </div>
        {width >= 768 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: 20,
            }}
            className="header_items"
          >
            <h4
              style={{
                fontSize: "15px",
                cursor: "pointer"
              }}
              onClick={() => {
                let offsetTop = document.getElementById("landing").offsetTop;
                window.scrollTo({
                  top: offsetTop - 100,
                  behavior: "smooth",
                });
              }}

            >
              Home
            </h4>
            <h4
              style={{
                marginLeft: 40,
                fontSize: "15px",
                cursor: "pointer"

              }}
              onClick={() => {
                let offsetTop = document.getElementById("about").offsetTop;
                window.scrollTo({
                  top: offsetTop - 100,
                  behavior: "smooth",
                });
              }}
            >
              About
            </h4>
            <h4
              style={{
                marginLeft: 40,
                fontSize: "15px",
                cursor: "pointer"

              }}
              onClick={() => {
                let offsetTop = document.getElementById("services").offsetTop;
                window.scrollTo({
                  top: offsetTop - 100,
                  behavior: "smooth",
                });
              }}
            >
              Services
            </h4>
          </div>
        ) : (
          <div
            style={{
              marginRight: 20,
            }}
            onClick={() => {
              setModal(true);
            }}
          >
            <img src={img2} width="30px" />
          </div>
        )}
      </div>
      {modal && (
        <div
          style={{
            backgroundColor: "rgba(33, 47, 61, 0.88)",
            height: "100vh",
            width: "100vw",
            position: "absolute",
            top: 0,
            left: 0,
          }}
          fullScreen
          open={modal}
          // TransitionComponent={Transition}
          // keepMounted
          maxWidth="lg"
          onClose={() => setModal(false)}
        >
          <div
            style={{
              display: "flex",
              padding: 50,
              alignItems: "center",
              flexDirection: "column",
              // height: "60vh",
              // width: "100vw",
              height: "100%",
              width: "100%",
              // opacity: "0.4",
            }}
          >
            <img
              onClick={() => {
                setModal(false);
              }}
              src={CloseImg} style={{ width: 20, height: 20, alignSelf: 'flex-end' }}
            />
            {/* <h4
              style={{
                fontSize: "20px",
                fontWeight: "normal",
                alignSelf: "flex-end",
                justifySelf: "flex-start",
                color: "white",
              }}
              onClick={() => {
                setModal(false);
              }}
            >
              x
            </h4> */}
            <div style={{ height: "10vh" }} />

            <h4
              style={{
                fontSize: "20px",
                color: "white",
                fontWeight: "normal",
                fontFamily: 'Arial,sans-serif',
                letterSpacing: '3px',
                marginBottom: 20
              }}
              onClick={() => {
                setModal(false);
                let offsetTop = document.getElementById("landing").offsetTop;
                window.scrollTo({
                  top: offsetTop - 100,
                  behavior: "smooth",
                });
              }}
            >
              LANDING
            </h4>
            <h4
              style={{
                fontSize: "20px",
                color: "white",
                fontWeight: "normal",
                fontFamily: 'Arial,sans-serif',
                letterSpacing: '3px',
                marginBottom: 20
              }}
              onClick={() => {
                setModal(false);
                let offsetTop = document.getElementById("about").offsetTop;
                window.scrollTo({
                  top: offsetTop - 100,
                  behavior: "smooth",

                });
              }}
            >
              ABOUT
            </h4>
            <h4
              style={{
                fontSize: "20px",
                color: "white",
                fontWeight: "normal",
                fontFamily: 'Arial,sans-serif',
                letterSpacing: '3px',
                marginBottom: 20

              }}
              onClick={() => {
                setModal(false);
                let offsetTop = document.getElementById("services").offsetTop;
                window.scrollTo({
                  top: offsetTop + 500,
                  behavior: "smooth",
                });
              }}
            >
              SERVICES
            </h4>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;