import React, { useState } from "react";
import "./home.css";
import landingImage from "../../assets/Mahesh_assets/landing.png";
import landingImage3 from "../../assets/Mahesh_assets/about.jpg";
import landingImage2 from "../../assets/Mahesh_assets/_H5A0017.JPG";
import testimonial1 from "../../assets/Mahesh_assets/bharatpierra.jpeg";
import testimonial2 from "../../assets/Mahesh_assets/sheerin.jpeg";

import ptImg from "../../assets/Mahesh_assets/about3.png";

import services1 from "../../assets/Mahesh_assets/services1.jpg";
import services2 from "../../assets/Mahesh_assets/services2.jpg";
import services3 from "../../assets/Mahesh_assets/services3.jpg";

import AboutImg from "../../assets/Mahesh_assets/about_founder.jpg";
import Carousel1 from "../../components/Carousel/Carousel1";
import Carousel2 from "../../components/Carousel/Carousel2";

import Img1 from "../../assets/Mahesh_assets/1.jpeg";
import Img2 from "../../assets/Mahesh_assets/2.jpeg";
import Img3 from "../../assets/Mahesh_assets/3.jpeg";
import Img4 from "../../assets/Mahesh_assets/4.jpeg";
import stadium from "../../assets/Mahesh_assets/stadium3.jpg";
import wellzap from "../../assets/Mahesh_assets/welzap.png";
import maheshguru from "../../assets/Mahesh_assets/maheshguru.jpg";
import WhyMscImg from "../../assets/Mahesh_assets/whyMsc.jpg";

import {
  Dialog,
  DialogContent,
  Grid,
  Divider,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

function Home() {
  const [width, setWidth] = useState(window.innerWidth);

  const ServiceItem = ({ item }) => {
    const [showInfo, setShowInfo] = useState(false);
    const handleClose = () => {
      setShowInfo(false);
    };

    return (
      <div
        className="service_item"
        style={{ backgroundImage: `url(${item.img})` }}
      >
        <div onClick={() => setShowInfo(!showInfo)} className="service_btn">
          {item.name}
        </div>
        {width >= 768 && (
          <div
            className={`service_info`}
            style={
              showInfo
                ? { bottom: 0, opacity: 1 }
                : { bottom: "-90vh", opacity: 0 }
            }
          >
            <h3
              style={{
                marginTop: 40,
              }}
            >
              {item.charge}
            </h3>
            <ul>
              {item?.dtls?.map((dtl) => (
                <li>{dtl}</li>
              ))}
            </ul>
            <p
              style={{
                marginTop: -10,
              }}
            >
              {item.text}
            </p>
            <p>{item.text2}</p>
            <div>
              <div
                onClick={() => setShowInfo(!showInfo)}
                className="service_btn"
              >
                Close
              </div>
            </div>
          </div>
        )}
        {width < 768 && (
          <Dialog fullScreen open={showInfo} onClose={handleClose}>
            <div
              style={{
                width: "100%",
                height: "100%",
                padding: 20,
                textAlign: "justify",
                backgroundColor: "gray",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <h3
                  style={{
                    fontSize: 18,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {item.charge}
                </h3>
                <ul style={{ padding: 0 }}>
                  {item?.dtls?.map((dtl) => (
                    <li>{dtl}</li>
                  ))}
                </ul>
                <p>{item.text}</p>
                <p>{item.text2}</p>
                {/* <div
                onClick={() => setShowInfo(!showInfo)}
                className="service_btn1"
              >
                Close
              </div> */}
              </div>
              <div
                style={{
                  border: "1px solid white",
                  marginTop: 50,
                  padding: "10px 20px",
                }}
                onClick={handleClose}
              >
                Close
              </div>
            </div>

            {/* <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions> */}
          </Dialog>
        )}
      </div>
    );
  };

  return (
    <div className="home">
      <div
        className="landing"
        id="landing"
        style={{
          backgroundImage: ` linear-gradient(
                    to right,
                    rgba(249, 21, 10 ,0.3),
                    rgba(0,0,0, 0.4)
                  ), url(${width >= 768 ? stadium : stadium})`,
        }}
      >
        <h1 style={{ alignContent: "left" }}>MSC Academy</h1>
        <h3>PERFORMANCE ENGINEERING CENTER</h3>
      </div>

      {/* <div
        className="section-2"
        style={{ backgroundImage: ` url(${landingImage2})` }}
      >
        <div className="textWrpr">
          <div className="qouteWrpr">
            <h3>"</h3>
            <p>
              Fitness is a bsic requirement for any sportsman in order to create
              his/her mark in their specified sport. <br />
              Our team here at MSC Academy strives to deliver a professional
              training setup in a very convenient manner. We will help channel
              your struggle into results on the field.
              <br />
            </p>
          </div>
         
        </div>
      </div> */}

      <div
        className="about2"
        style={{ backgroundImage: `url(${landingImage2})` }}
        id="about2"
      >
        <div className="content2">
          {/* <div className="sub">
            <div className="subwrpr">
              <p>
                Today I am a proud trainer to have who has worked with oer 300 people, including 700 profesional atheltes.
              </p>
            </div>
            <p className="name">
              <span>- Coach Mahesh, </span>
              Founder - MSC
            </p>
          </div> */}
          <div className="about_2_2">
            <h1>ABOUT MSC ACADEMY</h1>
            <div className="line"></div>
            <p>
              Fitness is a basic requirement for any sportsman in order to
              create his/her mark in their specified sport. <br /> <br></br>
              Our team here at MSC Academy strives to deliver a professional
              training setup in a very convenient manner. We will help channel
              your struggle into results on the field. <br />
              <br></br>M S C Academy aims in providing quality fitness training
              to all those who wish to shine in their fitness capabilities and
              help the young and upcoming athletes to win and achieve big.
            </p>
          </div>
        </div>
      </div>

      <div className="aim_sec">
        <div className="aim_text_wrpr">
          <h2>Starting our journey with a vision</h2>
          <div className="line" />
          <p>
            Our team started off our journey with a few goals.
            <br />
            <ul>
              <li> Gift our Athletes the best qualified training journey.</li>
              <li>
                {" "}
                Educate young athletes about various training programs and
                create awareness about the various available sport facilities
                and support.
              </li>
              <li>
                Reach out to the unnoticed places and provide good training
                facilities to all the upcoming sport talents.
              </li>
              <li>
                {" "}
                Generate EMPLOYMENT to all those sport stars who failed to reach
                their mark. Encourage and educate them to take up fitness
                courses along with the needed exposure and experience and
                support them with a fitness training job.
              </li>
            </ul>
          </p>
        </div>
        {width >= 768 && (
          <div className="corousel_wrpr">
            <Carousel1 />
          </div>
        )}
      </div>
      <div
        className="about"
        style={{ backgroundImage: `url(${AboutImg})` }}
        id="about"
      >
        <div className="content">
          {/* <div className="sub">
            <div className="subwrpr">
              <p>
                Today I am a proud trainer to have who has worked with oer 300 people, including 700 profesional atheltes.
              </p>
            </div>
            <p className="name">
              <span>- Coach Mahesh, </span>
              Founder - MSC
            </p>
          </div> */}
          <div className="about_2">
            <h1>ABOUT FOUNDER</h1>
            <div className="line"></div>
            <p>
              Mr. Mahesh Kumar S is a zestful and ardent fitness coach. He
              started his journey in the field of sport with weightlifting and
              then shifted his focus towards archery and went on to complete at
              the national level in no time. Coach Mahesh has trained the Indian
              Men’s National Basketball team who won gold at the 13th South
              Asian Games 2019. Coach Mahesh trains athletes from various sports
              including volleyball, archery and basketball and many more. He has
              trained over 3000 people including 700 athletes who have competed
              at the national and international level. He is currently working
              as a fitness coach for Gujarat Giants in Pro Kabaddi League 2021.
              Coach Mahesh always dreamt of providing the best support and sport
              requirements that he himself was denied during his sporting
              career. He believes M S C Academy is the best platform to make his
              dream a reality.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#D0D0D0",
          height: "50px",
          width: "100vw",
        }}
      ></div>
      {/* {width >= 768 && (
        <div className="corousel_wrpr">
          <Carousel2 />
        </div>
      )} */}

      <div className="services_1">
        <div className="img_wrpr">
          <img src={WhyMscImg} />
        </div>
        <div className="text_wrpr">
          <h2>Why M.S.C ?</h2>
          <div className="line" />
          <h3>
            M S C Academy aims in providing quality fitness training to all
            those who wish to shine in their fitness capabilities and help the
            young and upcoming athletes win and achieve big.
          </h3>
          <p>
            As our tagline says, here, our highly professional and passionate S
            & C coaches are ready to RESEARCH and ENGINEER the best training
            programs to enhance the performance for any unique body and
            lifestyle. At our academy, we strictly believe in data driven
            workouts and science-based programs. There are no excuses or
            shortcuts to reach goals! At MSC, we
          </p>
          <ul>
            <li> IDENTIFY - What quality do you want to improve -</li>
            <li> TEST - Choose a test to measure physical ability</li>
            <li>
              {" "}
              INTERVENTION : Research the best training program and implement
              training for a time period to improve performance.
            </li>
            <li>
              RE- TEST AND RE- DESIGN - To see if the training worked and make
              the necessary changes to get the the best performer in you.
            </li>
            <p>
              Our well determined and dedicated team is all set to collaborate
              with various other professional fitness setups and work together
              to discover and invent new fitness programs.
            </p>
          </ul>
          <h3> Never give up , try again! This time try with us.</h3>
        </div>
      </div>
      <div className="services" id="services">
        <div>
          <h1>SERVICES</h1>
          <div className="line" />
        </div>
        <div className="services_img_wrpr">
          {[
            {
              name: "ONLINE COACHING",
              img: services1,
              charge: "ONLINE COACHING",
              dtls: [
                "Step1:- Virtual fitness test before designing the program",
                "Step2:- Considering all test results, injury history and based on individual’s fitness level, a customized fitness program will be designed",
                "Step3:- Exercise/movements will be designed based on the trainee’s facility and make it more easier to workout.",
              ],
              text: "Once the workout starts, every week the trainer and trainee will have a scheduled call to track the progress, and to stress on ways to betterment. Trainee can call his fitness coach at any day. Yes !! we will be available anytime for you throughout your journey with us.",
              text2:
                "M S C Online fitness coaching is open for all clients - young and budding athletes, professional athletes, and regular clients of all ages.",
            },
            {
              name: "M S C HIGH PERFORMANCE COACHING",
              img: services2,
              charge: "M S C HIGH PERFORMANCE COACHING",
              text2:
                "Our programs are completely customized based on sport specific demands, athlete requirements, coaches Philosophy ,practice Model and Team makeup . Our professionals here research the best training methods to peak the performance.",
              text: "M S C training program starts with a standard fitness testing protocol and Sport specific Fitness test based on the sport with a physio check up and considering all the injury history the program starts off. Regular nutrition back up and consultation will happen. On a regular basis your RPE ,PHR, load management Sheet is maintained and performance assessment is done. High performance coaching also benefits mental fitness and provides a balanced Injury Free lifestyle. The reassessment of the training program is made based on the performance.",
            },
            {
              name: "MSC COMMUNITY MEMBERSHIP",
              img: services3,
              charge: "MSC COMMUNITY MEMBERSHIP",
              text2: "Coming Soon!",
              //text: "M S C training program starts with a standard fitness testing protocol and Sport specific Fitness test based on the sport with a physio check up and considering all the injury history the program starts off. Regular nutrition back up and consultation will happen. On a regular basis your RPE ,PHR, load management Sheet is maintained and performance assessment is done. High performance coaching also benefits mental fitness and provides a balanced Injury Free lifestyle. The reassessment of the training program is made based on the performance.",
            },
          ].map((item) => (
            <ServiceItem item={item} />
          ))}
        </div>
      </div>
      <div className="accelerate_sec">
        <h1>MSC ACADEMY</h1>
        <p>THE NUMBERS</p>
        <div className="line" />
        <div className="items_wrpr">
          {[
            { no: "180", title: "INTERNATIONAL ATHLETES" },
            { no: "300", title: "NATIONAL LEVEL ATHLETES" },
            { no: "30", title: "PRO KABADDI PLAYERS" },
            { no: "4", title: "OLYMPIANS" },
            { no: "15", title: "ASIAN GAMES ATHLETES" },
            { no: "12", title: "COMMONWEALTH GAMES ATHLETES" },
            { no: "15", title: "INTERNATIONAL TEAM CAMPS" },
          ].map((item) => (
            <div className="acc_item">
              <p className="no">{item.no}</p>
              <p className="title">{item.title}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="testimonials">
        <div className="item_wrpr">
          <h1>TESTIMONIALS</h1>
          <div className="line" />
          {[
            {
              pic: "https://firebasestorage.googleapis.com/v0/b/madcoach0.appspot.com/o/bharatpierra.jpeg?alt=media&token=c2ba5dc5-e18a-4b10-8ade-cd59f5d546eb",
              quote:
                "Mahesh is charismatic trainer who has a hunger for success that can be very contagious. He has the ability to really understand how to push different kinds of athletes both mentally and physically. His capability to both identify as well as utilise methods to motivate me was quite surprising as training had started to seem mundane and monotonous but he found a way to spice it up. It was a rarity to have found me unsatisfied after one of his sessions.",
              name: "BHARATH PEREIRA",
              desi: "YOUTH OLYMPIAN, INDIAN SPEED CLIMBER",
            },
            {
              pic: "https://firebasestorage.googleapis.com/v0/b/madcoach0.appspot.com/o/sheerin.jpeg?alt=media&token=0698c5b1-9a28-4673-80c9-89071d585e6c",
              quote:
                "Mahesh is a very knowledgeable fitness trainer. Along with training, he takes quite a few fun things so that the players don’t get bored of anything. He has new things coming our way everytime we step into the gym! Overall he is one of the best fitness coaches in the country.",
              name: "SHIREEN LIMAYE",
              desi: "INDIAN WOMEN'S BASKETBALL CAPTAIN",
            },
            {
              pic: "https://firebasestorage.googleapis.com/v0/b/mahesh-website-90afb.appspot.com/o/IMG-20191231-WA0025.jpg?alt=media&token=aeeefeff-da9e-4438-a77c-75d450faeeac",
              quote:
                "I first met Coach Mahesh in 2018 and I was really impressed by his humility and dedication.Our team wasn’t up to the mark at that time . But within three months ,he changed everything and we have won many tournaments since then.",
              name: "ANIL KUMAR BK",
              desi: "EKALAVYA AWARDEE AND INDIAN BASKETBALL PLAYER",
            },
            {
              pic: "https://firebasestorage.googleapis.com/v0/b/mahesh-website-90afb.appspot.com/o/PgILsbHt7W.jpg?alt=media&token=eaebe434-9169-4d83-81c1-1101af6decbc",
              quote: `He is one of the best S & C coaches in the country - I met him 4 years back with an injury and was
                not confident enough about fitness but in the season I was the best performer .His Positive
                spirit and scientific approach has changed many athletes' performance . Today many
                international players like me praise him for his work .`,
              name: "SUKESH HEGDE",
              desi: "STAR RAIDER AT PRO KABADDI LEAGUE AND SOUTH ASIAN GOLD MEDALIST",
            },
            {
              pic: "https://firebasestorage.googleapis.com/v0/b/mahesh-website-90afb.appspot.com/o/jayant-talukdar-1620056598.jpg?alt=media&token=364e83c7-801a-4d9a-afa8-a9b1ac06b994",
              quote: `He has trained me during my camp for the Tokyo Olympics. There is no doubt that Coach Mahesh is not only
                one of the best trainers in the country, but his experience and  knowldegde in the field is also unmatched.`,
              name: "JAYANTH TALUKDAR",
              desi: "OLYMPIAN - PARTICIPATED IN 2 OLYMPICS",
            },
          ].map((item) => (
            <div className="item">
              <img src={item.pic} />
              <div>
                <p className="quote">{item.quote}</p>
                <p className="name">
                  <span style={{ color: "#e2bc00", fontSize: 18 }}>
                    {item.name},{" "}
                  </span>
                  {item.desi}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="sponsor">
          <h1>OUR PARTNERS</h1>
          <div className="line-sponsor" />
          <div className="sponsorImages">
            <img className="sponsor1" src={wellzap} width="200" />
            <img src={maheshguru} width="200" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
